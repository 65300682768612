import React, {useContext} from "react";
import {Fade} from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
import landingPerson from "../../assets/lottie/landingPerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { TypeAnimation } from 'react-type-animation';
import {illustration, greeting} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function Greeting() {
  const {isDark} = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1
                className={isDark ? "dark-mode greeting-text" : "greeting-text"}
              >
                {" "}
                {greeting.title}{" "}
                <span className="wave-emoji">{emoji("👋")}</span>
              </h1>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                {/* <div style={{height: '20px'}}> */}
                <TypeAnimation
                  sequence={greeting.sequence} // Same String at the start will only be typed once, initially
                  speed={60} // Custom Speed from 1-99 - Default Speed: 40
                  deletionSpeed={75}
                  wrapper="div" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                  // style={{fontSize: '1em', height: '1.3em'}}
                />
                {/* </div> */}
                {greeting.subTitle}
              </p>
              <SocialMedia />
              <div className="button-greeting-div">
                <Button text="Contact me" href="#contact" />
                {greeting.resumeLink && (
                  <Button
                    text="See my resume"
                    newTab={true}
                    href={greeting.resumeLink}
                  />
                )}
                {/* {process.env.REACT_APP_RESUME_LINK && (
                  <Button
                    text="See my resume"
                    newTab={true}
                    href={process.env.REACT_APP_RESUME_LINK}
                  />
                )} */}
              </div>
            </div>
          </div>
          <div className="greeting-image-div">
            <img
                alt="Yuan Tsai"
                src={require("../../assets/images/profile.jpg")}
                style={{borderRadius: '12px', objectFit: 'cover', maxHeight: '500px', marginLeft: 'auto', marginRight: 'auto', display: "block"}}
              ></img>
            {/* {illustration.animated ? (
              <DisplayLottie animationData={landingPerson} />
            ) : (
              <img
                alt="Yuan Tsai"
                src={require("../../assets/images/profile.jpg")}
                style={{borderRadius: '12px', objectFit: 'cover', maxHeight: '550px', marginLeft: 'auto', marginRight: 'auto', display: "block"}}
              ></img>
            )} */}
          </div>
        </div>
      </div>
    </Fade>
  );
}
